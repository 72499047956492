// OverallAnalysis.jsx
import React from 'react';
import './index.scss'; // Import the custom CSS file
import { Bar } from 'react-chartjs-2'; // Import required chart component from react-chartjs-2

const OverallAnalysis = ({ summaryData, kycData, irregularitiesData, monthlyBalanceData, monthlyTransactionData }) => {
  return (
    <div className="overall-analysis-container">
      {/* Top Summary Blocks */}
      <div className="summary-blocks">
        <div className="summary-block">
          <h2>{summaryData.insightAIScore}</h2>
          <p>InsightAI SCORE</p>
        </div>
        <div className="summary-block">
          <h2>{summaryData.creditScore}</h2>
          <p>Credit SCORE</p>
        </div>
        <div className="summary-block">
          <h2>{summaryData.circularTxns}</h2>
          <p>CIRCULAR TXNS</p>
        </div>
        <div className="summary-block">
          <h2>{summaryData.bouncedCheques}</h2>
          <p>BOUNCED CHEQUES</p>
        </div>
        <div className="summary-block">
          <h2>{summaryData.transactions}</h2>
          <p>TRANSACTIONS<br />{summaryData.txnPeriod}</p>
        </div>
        <div className="summary-block">
          <h2>₹{summaryData.monthlyAvgBalance.toLocaleString()}</h2>
          <p>MONTHLY AVG BALANCE</p>
        </div>
      </div>

      {/* KYC Details and Irregularities */}
      <div className="details-row">
        <div className="details-block">
          <h3>KYC Details</h3>
          <table className="details-table">
            <tbody>
              {Object.entries(kycData).map(([key, value], index) => (
                <tr key={index}>
                  <td className="details-field">{key}</td>
                  <td className="details-value">{value}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="details-block">
          <h3>Irregularities</h3>
          <table className="details-table">
            <thead>
              <tr>
                <th>Activity</th>
                <th>Incidences</th>
              </tr>
            </thead>
            <tbody>
              {irregularitiesData.map((item, index) => (
                <tr key={index}>
                  <td>{item.activity}</td>
                  <td>{item.incidences}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* Monthly Average Balance and Monthly Transactions Comparison */}
      <div className="charts-row">
        <div className="chart-block">
          <h3>Month-wise Average Balance (₹)</h3>
          <Bar
            data={{
              labels: monthlyBalanceData.map((data) => data.month),
              datasets: [
                {
                  label: 'Average Balance (₹)',
                  data: monthlyBalanceData.map((data) => data.avgBalance),
                  backgroundColor: '#4BC0C0',
                  borderRadius: 5,
                  barThickness: 12,
                },
              ],
            }}
            options={{
              responsive: true,
              plugins: {
                legend: { display: false },
              },
              scales: {
                x: {
                  title: {
                    display: true,
                    text: 'Month',
                  },
                },
                y: {
                  beginAtZero: true,
                  title: {
                    display: true,
                    text: 'Average Balance (₹)',
                  },
                },
              },
            }}
          />
        </div>

        <div className="chart-block">
          <h3>Monthly Deposits & Withdrawals (₹)</h3>
          <Bar
            data={{
              labels: monthlyTransactionData.map((data) => data.month),
              datasets: [
                {
                  label: 'Deposits',
                  data: monthlyTransactionData.map((data) => data.deposits),
                  backgroundColor: '#7CB342',
                  borderRadius: 5,
                  barThickness: 12,
                },
                {
                  label: 'Withdrawals',
                  data: monthlyTransactionData.map((data) => data.withdrawals),
                  backgroundColor: '#FF9800',
                  borderRadius: 5,
                  barThickness: 12,
                },
              ],
            }}
            options={{
              responsive: true,
              plugins: {
                legend: { position: 'top' },
              },
              scales: {
                x: {
                  title: {
                    display: true,
                    text: 'Month',
                  },
                },
                y: {
                  beginAtZero: true,
                  title: {
                    display: true,
                    text: 'Amount (₹)',
                  },
                },
              },
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default OverallAnalysis;
