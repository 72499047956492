// TransactionInfo.jsx
import React from 'react';
import './index.scss'; // Import the custom CSS file

const TransactionInfo = ({ transactions }) => {
  return (
    <div className="transaction-container">
      <div className="transaction-table-wrapper">
        <table className="transaction-table">
          <thead>
            <tr>
              <th>Particulars</th>
              <th>Cheque/Ref nbr</th>
              <th>Counterparty</th>
              <th>Debit (₹)</th>
              <th>Credit (₹)</th>
              <th>Balance (₹)</th>
              <th>Computed Balance (₹)</th>
              <th>Category</th>
              <th>Tags</th>
              <th>UPI App</th>
            </tr>
          </thead>
          <tbody>
            {transactions.map((transaction, index) => (
              <tr key={index}>
                <td>{transaction.particulars}</td>
                <td>{transaction.chequeRefNbr}</td>
                <td>{transaction.counterparty}</td>
                <td>{transaction.debit || '-'}</td>
                <td>{transaction.credit || '-'}</td>
                <td>{transaction.balance}</td>
                <td>{transaction.computedBalance}</td>
                <td>{transaction.category}</td>
                <td>{transaction.tags}</td>
                <td>{transaction.upiApp}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TransactionInfo;
