import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Table } from 'reactstrap';
import { useNavigate } from "react-router-dom";

import Supply from "../../assets/images/DB/supply-1.png";
import Revenue from "../../assets/images/DB/profit.png";
import Web from "../../assets/images/DB/browser.png";
import CarData from "../../assets/images/DB/car.png";

import './style.scss';
const sampleDB = [
    // {
    //     logo: Supply,
    //     db_name: 'Supply Chain',
    //     id: 'supply-chain'
    // },
    // {
    //     logo: Revenue,
    //     db_name: 'Revenue generation',
    //     id: 'revenue-generation'

    // },
    // {
    //     logo: Web,
    //     db_name: 'Web trafficking',
    //     id: 'web-trafficking'

    // },
    {
        logo: CarData,
        db_name: 'Car dataset',
        id: 'car-dataset'

    },
]

const DBSample = ({ isOpen, toggle }) => {
    const [dbList, showDBList] = useState(sampleDB);
    const [selectedDB, setSelectedDB] = useState({});
    const navigate = useNavigate();


    const handleDB = () => {

        if (selectedDB && Object.keys(selectedDB).length > 0) {

            navigate(`/database-intelligence/${selectedDB.id}`,
                {
                    state: {
                        schema: selectedDB.db_name,
                        connection_type: 'NEW',
                    }
                }
            );
        }
    }

    const handleSearch = (e) => {
        const search = e.target.value;
        const filtered = sampleDB.filter(db => db.db_name.toLowerCase().includes(search.toLowerCase()));
        showDBList(filtered);
    }
    return (

        <div>
            <Modal isOpen={isOpen} toggle={toggle} className="sampleDB-modal">

                    <div style={{
                        width: '100%',
                        height: '55px',
                        borderBottom: '1px solid #d8d6de',
                        display: 'flex',
                        justifyContent: 'space-between',
                        padding: '15px 15px 0'
                    }}>
                        <div style={{
                            height: '18px',
                            fontSize: '20px',
                            fontWeight: 'bold',
                        }}>Select Sample Database</div>
                        <input
                            type="text"
                            className="db-search"
                            placeholder="Search Database..."
                            onChange={handleSearch}
                            style={{
                                height: '30px',
                                width: '300px',
                                borderRadius: '5px',
                                border: '1px solid #d8d6de',
                                padding: '0 10px',
                            }} />
                    </div>
                <ModalBody>
                    <div></div>
                    <div className='sampleDBList'>
                        {
                            dbList.map((db, index) => (
                                <div
                                    className={selectedDB === db ? "sampleDB-card active" : "sampleDB-card"}
                                    key={index}
                                    onClick={() => setSelectedDB(db)}
                                >
                                    <div className="sampleDB-card__img">
                                        <img src={db.logo} alt={db.db_name} />
                                    </div>
                                    <div className="sampleDB-card__title">{db.db_name}</div>
                                </div>
                            ))
                        }

                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" disabled={!(Object.keys(selectedDB).length > 0)} onClick={handleDB}>
                        Connect
                    </Button>
                    {' '}
                    <Button color="secondary" onClick={toggle}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
        </div >
    );
};

export default DBSample;
