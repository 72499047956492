import "./style.scss";
import React, { useEffect, useRef, useState } from "react";
import { useParams, Link, useNavigate, useLocation } from 'react-router-dom';
import { Archive, File, Upload } from "react-feather";
import PageHeader from "../../../@core/components/PageHeader";
import botImage from "../../../assets/images/bot/darkbot@2x.png";
import { FiCommand } from "react-icons/fi";
import { Card, Spinner, CardBody } from "reactstrap";
import { fetchOrganisationData } from "../../../redux/actions";
import { connect } from "react-redux";
import { submitBotSearchMessage } from "../../../services/onboarding";
import {
  uploadDataFile,
  loadSessionDocumentHandler,
  loadSessionMessagesHandler,
  fetchQueryResponseHandler,
} from "../../../services/documentQnA";
import './chatInterface.css'; //
import TypingEffect from '../../components/typingEffect'
import CustomTabs from "../../components/CustomTabs";
import ZohoAuth from "../../components/ZohoAuth";
import UrlDataIntegrations from "../../components/UrlDataIntegrations";
import SourceContainer from "./SourceContainer";
import { botConversation, markdownConversation } from "../../../utility/testDB/conversation";
import MarkdownParser from "../../components/MarkdownTypingEditor"
import { statusCode } from "../../../utility/constants/utilObject";
import { fetchUploadedDocumentDetails } from "../../../utility/docFunctions";
import { getCurrentDate, renderDocumentIcon, showErrorToast, showSuccessToast, truncateStringToNPlace } from "../../../utility/helper";
import { storeLastUrl } from "../../../utility/Utils";
// Assets load
import AnalyzeDoc from '../../../assets/icons/analyze_document.png';
import AnalysisImg from '../../../assets/icons/database_connect.png';
import SampleDatabase from '../../../assets/icons/database_full.png';
import DBSample from "../../DBIntelligence/DBSample";
import NotificationModel from "../../Dashboard/NotificationModel";


const DocumentIntelligence = (props) => {
  const { session } = useParams();
  const navigate = useNavigate();
  const firstName = localStorage.getItem("first_name");

  // Data variable
  const [messages, setMessages] = useState([]); // These messages are loaded from th backend
  const [messagesList, setMessagesList] = useState([]); // These messages are loaded on the UI
  const [message, setMessage] = useState("");
  const [fileList, setFileList] = useState([]);
  const [sessionId, setSessionId] = useState(session);
  const [selectedTab, setSelectedTab] = useState(0);
  const [analyticsTypsOptionsVisible, setAnalyticsTypsOptionsVisible] = useState(false);
  const fileInputRef = useRef(null);

  // Integration variables
  const [zohoAuthModal, setZohoAuthModal] = useState(false);
  const [urlDataModal, setUrlDataModal] = useState(null);

  // Loaders
  const [documentLoader, setDocumentLoader] = useState(false);
  const [loaderMessage, setLoaderMessage] = useState(null);
  const [userMessageLoader, setUserMessageLoader] = useState(false);
  const [screenLoader, setScreenLoader] = useState(false);
  const [isChatLoaded, setIsChatLoaded] = useState(true);
  const [isDocumentLoaded, setIsDocumentLoaded] = useState(true);
  const [isChatInputEnabled, setIsChatInputEnabled] = useState(true);

  // Bot variables
  const { fetchOrganisationData, organisationData } = props;

  // Reference Variables
  const documentUploadRef = useRef(null);
  const chatContainerRef = useRef(null);
  const [isUserInputFocused, setIsUserInputFocused] = useState(false);
  const location = useLocation();

  //Sample DB
  const [showSampleDB, setShowSampleDB] = useState(false);

  const [notificationOpen, setNotificationOpen] = useState(false);

  // ** Function to toggle notificationbar
  const toggleNotification = () => setNotificationOpen(!notificationOpen);

  const userType = localStorage.getItem('user_type');

  useEffect(() => {
    if (userType) {
      setNotificationOpen(true);

      setTimeout(() => {
        setNotificationOpen(false);
      }, [5000])
    }
    localStorage.removeItem('user_type');
  }, [userType])

  "Sample DB"
  "============================"
  const toggleSmapleDB = () => {
    setShowSampleDB(prev => !prev)
  }

  "Storing the URL to Local"
  "============================"

  useEffect(() => {
    storeLastUrl(location.pathname);

  }, [location]);

  "Initali Load of the document"
  "============================"
  useEffect(() => {
    if (sessionId && sessionId !== 'new-session') {
      console.log("Loading session documents");
      setIsDocumentLoaded(false);
      loadSessionDocuments();
      loadChatMessages();
    } else {
      setAnalyticsTypsOptionsVisible(true);
    }
    messageFormattor();
  }, []);

  const isSessionIdValid = () => {
    return sessionId && sessionId !== 'new-session';
  }


  "Hanlders for document uplaod"
  "============================"

  const handleFileUpload = (file) => {
    if (!file) {
      alert('Please select a file first!');
      return;
    }

    const fileDetails = fetchUploadedDocumentDetails(file);

    setDocumentLoader(true);
    const formData = new FormData();
    formData.append('document', file);
    formData.append('name', file.name);
    formData.append('document_type', fileDetails.fileCategory);
    formData.append('size', Math.ceil(fileDetails.fileSizeInKB));
    if (isSessionIdValid()) { formData.append('session', sessionId) }

    let loaderMessage = 'Please wait while I upload the document';
    if (fileDetails.fileSizeInKB >= 2000) {
      loaderMessage = 'Please wait while I upload the document (This may take a while)';
    }
    setLoaderMessage(loaderMessage);
    const loaderTimeout = setTimeout(() => {
      scrollToBottomInstant();
      clearTimeout(loaderTimeout);
    }, 500);
    uploadDataFile(formData)
      .then(response => {
        setLoaderMessage(null);
        if (response.status == statusCode.HTTP_201_CREATED) {
          setFileList([...fileList, { ...response.data }]);
          if (!isSessionIdValid()) {
            setSessionId(response.data.session);
          }
          showSuccessToast('File uploaded successfully');
          addNewBotMessage('Document uploaded successfully. You can now start querying on this document.');
        } else {
          showErrorToast('Error uploading file');
          addNewBotMessage('Error uploading file. Please try again');
        }
        setDocumentLoader(false);
      })
      .catch((error) => {
        setLoaderMessage(null);
        addNewBotMessage('Error uploading file. Please try again');
        setDocumentLoader(false);
      });
  };

  const loadFile = (e) => {
    const file = e.target.files[0];
    const fileDetails = fetchUploadedDocumentDetails(file);
    // fileSizeInKB should be less than 5 mb
    if (fileDetails.fileSizeInKB > 5000) {
      showErrorToast('File size should be less than or equal to 5MB');
      return;
    }
    if (fileDetails.fileType == 'UNKNOWN') {
      showErrorToast('File type not supported. Please upload a valid document');
      return;
    }

    if (fileDetails.fileType == 'WEBP') {
      showErrorToast('Only PNG and JPEG image supported. Please upload a valid image file');
      return;
    }
    handleFileUpload(file);
  }

  const initLoadDocument = () => {
    setAnalyticsTypsOptionsVisible(false)
    if (fileList.length == 5) {
      addNewBotMessage('You have reached the maximum limit of 5 documents. You may use a new session to upload more documents.');
      const scrollTimeout = setTimeout(() => {
        scrollToBottomInstant();
        clearTimeout(scrollTimeout);
      }, 0);
    } else {
      fileInputRef.current.click();
    }
  }

  const loadSessionDocuments = () => {
    loadSessionDocumentHandler(sessionId)
      .then(response => {
        if (response.status == statusCode.HTTP_200_OK) {
          setFileList(response.data);
          setIsDocumentLoaded(true);
        } else {
          showErrorToast('Error loading documents. Please refresh the page and try again');
        }
      })
  }

  const renderDocument = () => {
    if (!isDocumentLoaded) {
      return (
        <div>Loading Docuement...</div>
      )
    } else if (isDocumentLoaded && fileList.length === 0) {
      return (
        <div style={{ textAlign: 'center', marginBlock: '50px', fontWeight: '700' }}>No document available</div>
      )
    } else {
      return (
        <ul className="document-list">
          {
            fileList.map((file, index) => {
              return (
                <li key={index} className="document-item">
                  <div>{renderDocumentIcon(file.document_type)} {file.name}</div>
                </li>
              )
            })
          }
        </ul>
      )

    }
  }
  const renderSingleDocument = () => {
    if (!isDocumentLoaded) {
      return (
        <div>Loading Docuement...</div>
      )
    } else if (isDocumentLoaded && fileList.length === 0) {
      return (
        <div style={{ textAlign: 'left', fontWeight: '700' }}>Upload Documents</div>
      )
    } else {
      return (
        <div className="document-list-mobile">
          {
            fileList.map((file, index) => {
              return (
                <div className="document-list-mobile__item">{renderDocumentIcon(file.document_type)} {file.name}</div>
              )
            })
          }
        </div>
      )

    }
  }
  "Hanlders for chat messages"
  "=========================="

  const randomAlphaNumeric = (length = 6) => {
    let s = '';
    Array.from({ length }).some(() => {
      s += Math.random().toString(36).slice(2);
      return s.length >= length;
    });
    return s.slice(0, length);
  };


  const scrollToBottom = () => {
    const scroll = chatContainerRef.current;
    if (scroll) {
      scroll.scrollTo({
        top: scroll.scrollHeight,
        behavior: 'smooth'
      });
    }
  };

  const scrollToBottomInstant = () => {
    const scroll = chatContainerRef.current;
    if (scroll) scroll.scrollTop = scroll.scrollHeight;
  };

  const loadChatMessages = () => {
    loadSessionMessagesHandler(sessionId)
      .then(response => {
        setLoaderMessage(null);
        setIsChatLoaded(true);
        if (response.status == statusCode.HTTP_200_OK) {
          loadPreviousMessages(response.data);
        } else {
          addNewBotMessage('Sorry I could not load previous messages. You can start fresh here.');
        }
      })
      .catch((error) => {
        setLoaderMessage(null);
        setIsChatLoaded(true);
        console.error('Error:', error);
        addNewBotMessage('Sorry I could not load previous messages. You can start fresh here.');
      })
  }

  const handleSendMessage = async (e) => {
    e.preventDefault();
    const input = documentUploadRef.current.value;
    documentUploadRef.current.value = "";
    setIsUserInputFocused(false);
    if (input.trim() !== "") {
      addNewUserMessage(input.trim());
      if (isSessionIdValid()) {
        fetchBotResponse(input.trim());
      } else {
        addNewBotMessage("Please upload a document first to get started. Thanks! 📄✨");
      }
    }
  };

  const fetchBotResponse = (query) => {
    if (!isSessionIdValid()) {
      addNewBotMessage("You have not uploaded any document yet. Please upload a document first.");
      return;
    }

    fetchQueryResponseHandler({
      session: sessionId,
      query: query
    })
      .then((response) => {
        setLoaderMessage(null);
        if (response.status == statusCode.HTTP_200_OK) {
          addBotResponseToUser(response.data.message);
        } else {
          addBotResponseToUser("I am currently facing some issues. Please try again later.");
        }
      })
      .catch((error) => {
        console.error('Error:', error);
        setLoaderMessage(null);
        addBotResponseToUser("I am currently facing some issues. Please try again later.");
      });
  }

  const handleKeyPress = async (event) => {
    if (event.key === "Enter") {
      event.preventDefault();  // Prevent the default behavior of a line break
      handleSendMessage(event);  // Replace this with your actual send message function
      documentUploadRef.current.value = "";
    }
  };

  const handleFocus = () => setIsUserInputFocused(true);

  const handleBlur = () => setIsUserInputFocused(false);

  const addBotMessageWithMarkdown = (message) => {
    setMessagesList([
      ...messagesList,
      <div key={randomAlphaNumeric()} className={"bot-image-text-container"}>
        <div className={"bot-image-container"}
          style={{ background: "#90CAF9" }}>
          <img className={"bot-image"}
            src={botImage}
            alt={""} />
        </div>
        <div className="message bot-message">
          <MarkdownRenderer markdownText={message} />
        </div>
      </div>
    ]);
  }

  const addNewBotMessageWithoutTyping = (botMessage) => {
    setMessagesList(prevMessages => [
      ...prevMessages,
      <div key={randomAlphaNumeric()} className={"bot-image-text-container"}>
        <div className={"bot-image-container"}
          style={{ background: "#90CAF9" }}>
          <img className={"bot-image"}
            src={botImage}
            alt={""} />
        </div>
        <div className="message bot-message">
          <MarkdownParser texts={[botMessage]} isTyping={false} />
        </div>
      </div>
    ]);
  }

  const addNewBotMessage = (botMessage) => {
    setMessagesList(prevMessages => [
      ...prevMessages,
      <div key={randomAlphaNumeric()} className={"bot-image-text-container"}>
        <div className={"bot-image-container"}
          style={{ background: "#90CAF9" }}>
          <img className={"bot-image"}
            src={botImage}
            alt={""} />
        </div>
        <div className="message bot-message">
          {/* <TypingEffect texts={[botMessage]}/> */}
          <MarkdownParser texts={[botMessage]} />
        </div>
      </div>
    ]);
  }

  const addBotResponseToUser = (botMessage) => {
    // setMessagesList(messagesList.slice(0, messagesList.length - 1));
    addNewBotMessage(botMessage);
  }

  const addOldUserMessage = (userMessage) => {
    setMessagesList(prevMessages => [
      ...prevMessages,
      <div key={randomAlphaNumeric()}>
        <div className={`message user-message`}>
          <div>{userMessage}</div>
        </div>
        <div className={"user-message-date"}>{getCurrentDate()}</div>
      </div>
    ]);
  }

  const addNewUserMessage = (userMessage) => {
    setMessagesList((prevMessages) => {
      const updatedMessages = [...prevMessages,
      <div key={randomAlphaNumeric()}>
        <div className={`message user-message`}>
          <div>{userMessage}</div>
        </div>
        <div className={"user-message-date mobile-hidden"}>{getCurrentDate()}</div>
      </div>]
      return updatedMessages;
    });

    scrollToBottomInstant();

    if (isSessionIdValid()) {
      setLoaderMessage('Please wait while I fetch the response for your query');
    }
  }

  const messageFormattor = () => {
    if (isSessionIdValid() && messagesList.length === 0 && isChatLoaded) {
      addNewBotMessage(`Welcome back!!👋 ${firstName}`);
      setLoaderMessage('Please wait while I fetch your previous chat history');
    } else if (isSessionIdValid() && messagesList.length > 0) {
      // addNewBotMessage(`Welcome back!!👋 ${firstName}. Please wait while I fetch your previous chat history...`);
    } else if (!isSessionIdValid()) {
      addNewBotMessage(`Welcome!!👋 ${firstName}. I am your personal assistant.`);
      const responseTimeout = setTimeout(() => {
        addNewBotMessage(`Please upload your documents, and I can help you query on that knowledge base 😇.`);
        clearTimeout(responseTimeout);
      }, 1500);
    }
  }

  const loadPreviousMessages = (loadedMessages) => {
    //Remove only the last message in the messageList
    loadedMessages.forEach((messageItem) => {
      if (messageItem.is_bot) {
        addNewBotMessageWithoutTyping(messageItem.message);
      } else { addOldUserMessage(messageItem.message); }
    })
    const scrollTimeout = setTimeout(() => {
      scrollToBottom();
      clearTimeout(scrollTimeout);
    }, 500);
  }

  "Tab Panels"
  "=========================="

  const renderDocumentTabPanel = () => {
    if (selectedTab === 0) {
      return (
        <>
          <ul className="document-list">
            {renderDocument()}
          </ul>

          {/* Action Buttons */}
          <input ref={fileInputRef} type="file" onChange={loadFile} style={{ display: 'none' }} />
          {
            !documentLoader &&
            <button className="upload-btn"
              onClick={initLoadDocument}
              style={{
                background: '#A5D6A7',
                color: '#00695C',
                fontWeight: 'bold',
                cursor: 'pointer',
              }}>Upload Document</button>
          }
          {
            documentLoader &&
            <button className="upload-btn"
              onClick={initLoadDocument}
              style={{
                background: '#A5D6A7',
                color: '#00695C',
                fontWeight: 'bold',
                cursor: 'pointer',
              }}>Uploading Document...</button>
          }

          {/* Navigations */}
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <button
              onClick={() => window.location.href = '/document-intelligence/new-session'}
              style={{
                marginTop: '5px',
                width: '49%',
                background: '#90CAF9',
                color: '#283593',
                cursor: 'pointer'
              }} className="upload-btn">New</button>
            <Link
              to='/document-intelligence-history'
              style={{
                marginTop: '5px',
                width: '49%',
                background: '#90CAF9',
                color: '#283593',
                cursor: 'pointer',
                borderRadius: '5px',
                textAlign: 'center',
                // make vertical center
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}>History</Link>
          </div>
        </>
      )
    } else if (selectedTab === 1) {
      return (
        <SourceContainer
          onClickSource={(index) => {
            switch (index) {
              case 0:
                setUrlDataModal('Website');
                break;
              case 1:
                setUrlDataModal('Youtube');
                break;
              case 2:
                setZohoAuthModal(true);
                break;
              case 3:
                break;
            }
          }}
        />
      )
    }
  }

  const renderDocumentMobileTabPanel = () => {
    if (selectedTab === 0) {
      return (
        <>
          <span className="document-list" >
            {renderSingleDocument()}
          </span>

          {/* Action Buttons */}
          <input ref={fileInputRef} type="file" onChange={loadFile} style={{ display: 'none' }} />
          {
            !documentLoader &&
            <button className="upload-btn"
              onClick={initLoadDocument}
              style={{
                background: '#A5D6A7',
                color: '#00695C',
                fontWeight: 'bold',
                cursor: 'pointer',
              }}><Upload  size={16}/></button>
          }
          {
            documentLoader &&
            <button className="upload-btn"
              onClick={initLoadDocument}
              style={{
                background: '#A5D6A7',
                color: '#00695C',
                fontWeight: 'bold',
                cursor: 'pointer',
              }}>...</button>
          }

          {/* Navigations */}
            {/* <button
              onClick={() => window.location.href = '/document-intelligence/new-session'}
              style={{
                marginTop: '5px',
                width: '49%',
                background: '#90CAF9',
                color: '#283593',
                cursor: 'pointer'
              }} className="upload-btn">New</button> */}
            <Link
              to='/document-intelligence-history'
              style={{
                width: '64px',
                background: '#90CAF9',
                color: '#283593',
                cursor: 'pointer',
                borderRadius: '5px',
                textAlign: 'center',
                marginLeft: '10px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}><Archive size={16}/></Link>
        </>
      )
    } else if (selectedTab === 1) {
      return (
        <SourceContainer
          onClickSource={(index) => {
            switch (index) {
              case 0:
                setUrlDataModal('Website');
                break;
              case 1:
                setUrlDataModal('Youtube');
                break;
              case 2:
                setZohoAuthModal(true);
                break;
              case 3:
                break;
            }
          }}
        />
      )
    }
  }

  const closeUrlDataModel = () => {
    setUrlDataModal(null);
  }

  const renderAnalysisSelectOption = () => {
    if (analyticsTypsOptionsVisible) {
      return (<div
        style={{
          position: "absolute",
          top: "40%",
          left: "10",
          width: "70%",
          height: "30%",
          display: "flex",
        }}
        className="analysis-wrapper"
      >
        <Card className="analysis-wrapper__card" onClick={() => {
          initLoadDocument();
          setAnalyticsTypsOptionsVisible(false);
        }}>
          <CardBody>
            <div className='card-img'>
              <img src={AnalyzeDoc} />
            </div>
            <h2>Upload Document</h2>
          </CardBody>
        </Card>

        <Card className="analysis-wrapper__card" onClick={() => { navigate('/database-intelligence') }}>
          <CardBody>
            <div className='card-img'>
              <img src={AnalysisImg} />
            </div>
            <h2>Connect Your Database</h2>
          </CardBody>
        </Card>

        <Card className="analysis-wrapper__card" onClick={toggleSmapleDB}>
          <CardBody>
            <div className='card-img'>
              <img src={SampleDatabase} />
            </div>
            <h2>Need a Database? <span style={{ display: 'block', marginTop: '5px' }}>Try our samples!</span></h2>
          </CardBody>
        </Card>

        <DBSample isOpen={showSampleDB} toggle={toggleSmapleDB} />
      </div>)
    }
  }

  return (
    <div className="document-intelligence">
      <PageHeader
        pageName={"Document Intelligence"}
        backButtonEnabled={false}
      />
      <Card className="blogPreviewContainer">
        <div className='main-container vh-85 padding_0'>
          <div className="mobile-bar">
            {renderDocumentMobileTabPanel()}
          </div>
          <div className="left-panel">
            <CustomTabs
              tabs={["Document"]}
              onTabSelect={setSelectedTab}
            />
            {renderDocumentTabPanel()}
          </div>
          <div className="chatbot-section live-chat right-panel">
            <div
              ref={chatContainerRef}
              style={{
                width: "100%",
                height: "100%",
                overflowY: "scroll"
              }}
            >
              {messagesList}
              {renderAnalysisSelectOption()}
              {loaderMessage &&
                <div key={"9890809"} className={"bot-image-text-container"}>
                  <div className={"bot-image-container"}
                    style={{ background: "#90CAF9" }}>
                    <img className={"bot-image"}
                      src={botImage}
                      alt={""} />
                  </div>
                  <div className="message bot-message">
                    <TypingEffect
                      texts={[loaderMessage]}
                      addLoader={true}
                    />
                  </div>
                </div>
              }
            </div>
            <div>
              <form className={`chat-bot-input-container ${isUserInputFocused ? "focused" : ""}`}
                onSubmit={handleSendMessage} onKeyPress={handleKeyPress}>
                <input
                  className={"chat-bot-input"}
                  type="text"
                  placeholder="Ask any question pertaining to your document...."
                  disabled={!isChatInputEnabled}
                  autoFocus={true}
                  ref={documentUploadRef}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                />
                <button className="send-button" type={"submit"}>
                  <i color={"#7169e8"} className="arrow-icon"></i>
                </button>
              </form>
            </div>
          </div>
        </div>
      </Card>
      {
        zohoAuthModal &&
        <ZohoAuth
          onClose={() => setZohoAuthModal(false)}
        />
      }
      {
        urlDataModal &&
        <UrlDataIntegrations
          urlType={urlDataModal}
          onClose={closeUrlDataModel}
        />
      }
      {notificationOpen ?
        <NotificationModel open={notificationOpen} toggleNotification={toggleNotification} /> : <></>}
    </div>
  );
};

const mapDispatchToProps = {
  fetchOrganisationData
};

const mapStateToProps = ({ organisationReducer }) => {
  return {
    organisationData: organisationReducer.organisationData
  };
};


export default connect(mapStateToProps, mapDispatchToProps)(DocumentIntelligence);