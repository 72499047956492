import "./index.scss"
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import { Card, Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Col, Input, FormText } from "reactstrap";
import { Search } from "react-feather";
import PageHeader from "../../@core/components/PageHeader";
import FinancialStatement from "../../assets/icons/financial_statement_analytics.png";
import Loan from "../../assets/icons/loan.png";
import ScannedPdf from "../../assets/icons/scanned_pdf_analytics.png";
import BankStatement from "../../assets/icons/bank_statement_analytics.png";
import ZohoLogo from "../../assets/logo/zoho_logo.png";
import TallyLogo from "../../assets/logo/tally_logo.png";
import GSTLogo from "../../assets/logo/gst.png";
import VATLogo from "../../assets/logo/vat.webp";
import { fetchUploadedDocumentDetails } from "../../utility/docFunctions";
import { connect } from "react-redux";
import { showErrorToast } from "../../utility/helper";
import Banner from "../components/banner";
import PremiumIcon from "../../assets/icons/premium.png";
import { FaFileAlt, FaFilePdf, FaFileImage, FaTimes, FaTrash } from 'react-icons/fa'; // Icons for file types


const PRODUCT_TYPE = {
  FINANCIAL_STATEMENT: 'FINANCIAL_STATEMENT',
  TRAIL_BALANCE: 'TRAIL_BALANCE',
  SCANNED_PDF: 'SCANNED_PDF',
}

const fileIcons = {
  'Bank Statement': <FaFileAlt />,
  'Credit Bureau Report': <FaFilePdf />,
  'GSTIN': <FaFileImage />,
  'AML Report': <FaFileAlt />,
};


const LedgerAndBooks = () => {
  const navigate = useNavigate();
  const fileInputRef = useRef(null);
  const [modal, setModal] = useState(false);
  const [uploadInfo, setUploadInfo] = useState({
    name: '',
    documentType: '',
    file: null
  })

  // Multi files
  const [files, setFiles] = useState([]);
  const [documentType, setDocumentType] = useState('');
  const [fileName, setFileName] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [selectedBank, setSelectedBank] = useState('');
  const [showBankDropdown, setShowBankDropdown] = useState(false);

  // Data Variables
  const [selectedProduct, setSelectedProduct] = useState(null);

  const initLoadClick = (productType) => {
    setSelectedProduct(productType);
    setModal(!modal)
    // fileInputRef.current.click();
  }

  const loadFile = (e) => {
    // const file = e.target.files[0];
    console.log(uploadInfo.file);
    const file = uploadInfo.file;
    const fileDetails = fetchUploadedDocumentDetails(file);

    if (selectedProduct == PRODUCT_TYPE.FINANCIAL_STATEMENT) {
      if (fileDetails.fileCategory !== 'PDF') {
        showErrorToast('Please upload a PDF file');
      } else {
        navigate('/financial-statement-analytics/new-analysis', { state: { file: uploadInfo } });
      }
    } else if (selectedProduct == PRODUCT_TYPE.TRAIL_BALANCE) {
      if (fileDetails.fileCategory !== 'PDF') {
        showErrorToast('Please upload a PDF file');
      } else {
        navigate('/trial-balance-analytics/new-analysis', { state: { file: uploadInfo } });
      }
    } else if (selectedProduct == PRODUCT_TYPE.SCANNED_PDF) {
      if (fileDetails.fileCategory !== 'PDF') {
        showErrorToast('Please upload a PDF file');
      } else {
        navigate('/scanned-pdf-analytics/new-analysis', { state: { file: uploadInfo } });
      }
    }
  }

  //Modal Input

  const handleInput = (e) => {
    const { name, value } = e.target;
    setUploadInfo((prevInfo) => ({
      ...prevInfo,
      [name]: value,
    }));
  }

  const ledgerModal = () => {
    return (
      <>
        <div>

          <Modal isOpen={modal} toggle={initLoadClick} className="ledger-modal">
            <ModalBody>
              <Form>
                <FormGroup row>
                  <Label for="name" sm={3}>Name:</Label>
                  <Col sm={9}>
                    <Input
                      id="name"
                      name="name"
                      placeholder="Document Name"
                      type="text"
                      value={uploadInfo.name}
                      onChange={handleInput}
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label for="documentType" sm={3}>
                    Select Document Type
                  </Label>
                  <Col sm={9}>
                    <Input
                      type="select"
                      name="documentType"
                      id="documentType"
                      value={uploadInfo.documentType}
                      onChange={handleInput}
                    >
                      <option value="">Select Document Type</option>
                      <option value="text-pdf">Text PDF</option>
                      <option value="scanned-pdf">Scanned PDF / Image</option>
                    </Input>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label
                    for="file"
                    sm={3}
                  >
                    File
                  </Label>
                  <Col sm={9}>
                    <Input
                      id="file"
                      name="file"
                      type="file"
                      onChange={(e) => setUploadInfo({ ...uploadInfo, file: e.target.files[0] })}
                    />
                    {/* <FormText>
                      This is some placeholder block-level help text for the above input. It‘s a bit lighter and easily wraps to a new line.
                    </FormText> */}
                  </Col>
                </FormGroup>

              </Form>
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={loadFile}>
                Proceed
              </Button>{' '}
              <Button color="secondary" onClick={initLoadClick}>
                Cancel
              </Button>
            </ModalFooter>
          </Modal>
        </div>
      </>
    )
  };

  // Multi file uplaod
  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    const filesWithIcons = selectedFiles.map((file) => ({
      name: file.name.length > 30 ? file.name.substring(0, 30) + '...' : file.name,
      type: documentType,
      icon: fileIcons[documentType],
      file,
    }));
    setFiles((prevFiles) => [...prevFiles, ...filesWithIcons]);
    setShowBankDropdown(false);
  };

  const handleRemoveFile = (index) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const handleProceed = () => {
    // onProceed(files);
    navigate('/ai-underwriting/new-doc', { state: { files } });
  };

  const handleDocumentTypeChange = (e) => {
    setDocumentType(e.target.value);
    if (e.target.value === 'Bank Statement') {
      setShowBankDropdown(true); // Show bank dropdown if Bank Statement is selected
    } else {
      setShowBankDropdown(false); // Hide bank dropdown for other types
    }
  };

  const multiFileUploadModal = () => {
    return (
      <>
          <Modal isOpen={isOpen} toggle={()=>setIsOpen(false)} className="file-upload-modal">
            <ModalBody>
              <h3 style={{
                textAlign: 'center',
                marginBottom: '40px',
              }}>Underwriting Case Document</h3> {/* Added Header */}
              <Form>
                <FormGroup row>
                  <Label for="caseName" sm={3}>Case Name:</Label> {/* Changed to Case Name */}
                  <Col sm={9}>
                    <Input
                      id="caseName"
                      name="caseName"
                      placeholder="Enter Case Name"
                      type="text"
                      value={fileName}
                      onChange={(e) => setFileName(e.target.value)}
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label for="documentType" sm={3}>
                    Select Document Type
                  </Label>
                  <Col sm={9}>
                    <Input
                      type="select"
                      name="documentType"
                      id="documentType"
                      value={documentType}
                      onChange={handleDocumentTypeChange}
                    >
                      <option value="">Select Document Type</option>
                      <option value="Bank Statement">Bank Statement</option>
                      <option value="Credit Bureau Report">Credit Bureau Report</option>
                      <option value="GSTIN">GSTIN</option>
                      <option value="AML Report">AML Report</option>
                    </Input>
                  </Col>
                </FormGroup>

                {/* Conditional Bank Dropdown */}
                {showBankDropdown && (
                  <FormGroup row>
                    <Label for="bankSelect" sm={3}>
                      Select Bank
                    </Label>
                    <Col sm={9}>
                      <Input
                        type="select"
                        name="bankSelect"
                        id="bankSelect"
                        value={selectedBank}
                        onChange={(e) => setSelectedBank(e.target.value)}
                      >
                        <option value="">Select Bank</option>
                        <option value="HDFC">HDFC Bank</option>
                        <option value="SBI">State Bank of India</option>
                        <option value="ICICI">ICICI Bank</option>
                        <option value="Axis">Axis Bank</option>
                        <option value="Kotak">Kotak Mahindra Bank</option>
                        <option value="PNB">Punjab National Bank</option>
                        {/* Add more banks as needed */}
                      </Input>
                    </Col>
                  </FormGroup>
                )}

                <FormGroup row>
                  <Label for="file" sm={3}>
                    Upload File(s)
                  </Label>
                  <Col sm={9}>
                    <Input
                      id="file"
                      name="file"
                      type="file"
                      multiple
                      onChange={handleFileChange}
                      disabled={!documentType || (documentType === 'Bank Statement' && !selectedBank)} // Disable if no document type or bank selected
                    />
                  </Col>
                </FormGroup>
              </Form>
              {/* Uploaded Files List */}
              {files.length > 0 && (
                <div className="uploaded-files-list">
                  {files.map((file, index) => (
                    <div key={index} className="uploaded-file-item">
                      {file.icon} {file.name}
                      <div className="remove-file-button" onClick={() => handleRemoveFile(index)}>
                        <FaTrash />
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={handleProceed} disabled={files.length === 0}>
                Proceed
              </Button>
              <Button color="secondary" onClick={()=>setIsOpen(false)}>
                Cancel
              </Button>
            </ModalFooter>
          </Modal>
        </>
      );
  };

  return (
    <>
      {/* <Banner /> */}

      <Card>
        <PageHeader
          pageName={"Dashboard"}
          backButtonEnabled={false}
        />
        <div className='container-fluid vh-85'>
          {/* <div className='page-header'>
            <div className='tab-container'>
              <div className='section-head'>Select From Options Below</div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }} className='search-container'>
              <div className='search-area'>
                <input
                  className='header-search'
                  placeholder='Search...'
                />
                <Search size={18} color='#B0BEC5' />
              </div>
            </div>
          </div> */}
          <div className='page-container no-scroll-bar'>
            {/* Statement Container */}
            <input ref={fileInputRef} type="file" onChange={loadFile} style={{ display: 'none' }} />
            <div key={1} className="outer-template-container">
              <div className="template-container-header">
                Statement Analytics
              </div>
              <div className="template-container-items-list">
                <div key={2} className="template-container-item">
                  <div className="image-container">
                    <img className="image-item" src={Loan} alt="Trail Balance" />
                  </div>
                  <div className="header">
                    AI Underwriting
                  </div>
                  <div className="description">
                    Automates the underwriting process by analysing the bank statement statements and providing a detailed report.
                  </div>
                  <div className="overlay">
                    <button
                      onClick={() => { setIsOpen(true) }}
                      style={{ borderRight: '0.5px solid #FFFF' }}
                      className="action-btn">New</button>
                    <button onClick={() => { navigate('/ai-underwriting/new-doc') }} className="action-btn">History</button>
                  </div>
                </div>
                <div key={1} className="template-container-item">
                  <div className="image-container">
                    <img className="image-item" src={FinancialStatement} alt="Financial Statement" />
                  </div>
                  <div className="header">
                    Financial Statement Analysis
                  </div>
                  <div className="description">
                    Analyse all the financial statements details like balance sheet, cash flow statement, income statement and financial ratios.
                  </div>
                  <div className="overlay">
                    {/* <button className="action-btn"> <img src={PremiumIcon} alt='/' /> Premium </button> */}

                    <button
                      onClick={() => { initLoadClick(PRODUCT_TYPE.FINANCIAL_STATEMENT) }}
                      style={{ borderRight: '0.5px solid #FFFF' }}
                      className="action-btn">New</button>
                    <button onClick={() => { navigate('/financial-statements') }} className="action-btn">History</button>
                  </div>
                </div>
                
                {/* <div key={3} className="template-container-item" onClick={() => { navigate('/financial-statement-analyser') }}> */}
                <div key={3} className="template-container-item">
                  <div className="image-container">
                    <img className="image-item" src={BankStatement} alt="Trail Balance" />
                  </div>
                  <div className="header">
                    Bank Statement Analysis
                  </div>
                  <div className="description">
                    Analyse all the financial statements details like balance sheet, cash flow statement, income statement and financial ratios.
                  </div>
                  {/* <div className="overlay">
                    <button className="action-btn"> <img src={PremiumIcon} alt='/' /> Premium </button>
                  </div> */}
                  <div className="overlay">
                    <button
                      onClick={() => { navigate('/financial-statement-analyser') }}
                      style={{ borderRight: '0.5px solid #FFFF' }}
                      className="action-btn">New</button>
                    <button onClick={() => { navigate('/dashboard') }} className="action-btn">History</button>
                  </div>
                </div>
                <div key={4} className="template-container-item">
                  <div className="image-container">
                    <img className="image-item" src={ScannedPdf} alt="Scanned PDF Analytics" />
                  </div>
                  <div className="header">
                    Scanned PDF Analysis
                  </div>
                  <div className="description">
                    Analyse all the financial statements details like balance sheet, cash flow statement, income statement and financial ratios.
                  </div>
                  <div className="overlay">
                    {/* <button className="action-btn"> <img src={PremiumIcon} alt='/' /> Premium </button> */}

                    <button
                      onClick={() => { initLoadClick(PRODUCT_TYPE.SCANNED_PDF) }}
                      style={{ borderRight: '0.5px solid #FFFF' }}
                      className="action-btn">New</button>
                    <button onClick={() => { navigate('/scanned-pdfs') }} className="action-btn">History</button>
                  </div>
                </div>
              </div>
            </div>
            {/* Book Integration Container */}
            <div key={2} className="outer-template-container">
              <div className="template-container-header">
                {/* Ledger Books Integration */}
                Upcoming Features
              </div>
              <div className="template-container-items-list">
                <div key={1} className="template-container-item">
                  <div className="image-container">
                    <img className="image-item" src={ZohoLogo} alt="Financial Statement" />
                  </div>
                  <div className="header">
                    Zoho Books Integration
                  </div>
                  <div className="description">
                    Analyse all the financial statements details like balance sheet, cash flow statement, income statement and financial ratios.
                  </div>
                  <div className="overlay">
                    <button className="action-btn"> <img src={PremiumIcon} alt='/' /> Premium </button>
                    {/* <button className="action-btn">Comming Soon...</button> */}

                  </div>

                </div>
                <div key={2} className="template-container-item">
                  <div className="image-container">
                    <img className="image-item" src={TallyLogo} alt="Trail Balance" />
                  </div>
                  <div className="header">
                    Tally Integration
                  </div>
                  <div className="description">
                    Analyse all the financial statements details like balance sheet, cash flow statement, income statement and financial ratios.
                  </div>
                  <div className="overlay">
                    <button className="action-btn"> <img src={PremiumIcon} alt='/' /> Premium </button>
                  </div>
                </div>
                <div key={3} className="template-container-item">
                  <div className="image-container">
                    <img className="image-item" src={GSTLogo} alt="Financial Statement" />
                  </div>
                  <div className="header">
                    GST Taxation
                  </div>
                  <div className="description">
                    Analyse all the financial statements details like balance sheet, cash flow statement, income statement and financial ratios.
                  </div>
                  <div className="overlay">
                    <button className="action-btn"> <img src={PremiumIcon} alt='/' /> Premium </button>
                  </div>
                </div>
                <div key={4} className="template-container-item">
                  <div className="image-container">
                    <img className="image-item" src={VATLogo} alt="Trail Balance" />
                  </div>
                  <div className="header">
                    VAT Taxation
                  </div>
                  <div className="description">
                    Analyse all the financial statements details like balance sheet, cash flow statement, income statement and financial ratios.
                  </div>
                  <div className="overlay">
                    <button className="action-btn"> <img src={PremiumIcon} alt='/' /> Premium </button>
                  </div>
                </div>
              </div>
            </div>
            {/* Taxation
            <div key={3} className="outer-template-container">
              <div className="template-container-header">
                Taxation
              </div>
              <div className="template-container-items-list">
                <div key={1} className="template-container-item">
                  <div className="image-container">
                    <img className="image-item" src={GSTLogo} alt="Financial Statement" />
                  </div>
                  <div className="header">
                    GST Taxation
                  </div>
                  <div className="description">
                    Analyse all the financial statements details like balance sheet, cash flow statement, income statement and financial ratios.
                  </div>
                  <div className="overlay">
                    <button className="action-btn">Comming Soon...</button>
                  </div>
                </div>
                <div key={2} className="template-container-item">
                  <div className="image-container">
                    <img className="image-item" src={VATLogo} alt="Trail Balance" />
                  </div>
                  <div className="header">
                    VAT Taxation
                  </div>
                  <div className="description">
                    Analyse all the financial statements details like balance sheet, cash flow statement, income statement and financial ratios.
                  </div>
                  <div className="overlay">
                    <button className="action-btn">Comming Soon...</button>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </Card>
      {ledgerModal()}
      {multiFileUploadModal()}
    </>
  );
}

const mapDispatchToProps = {

};

const mapStateToProps = ({ }) => {
  return {};
};


export default connect(mapStateToProps, mapDispatchToProps)(LedgerAndBooks);