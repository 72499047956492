// KYCInfo.jsx
import React from 'react';
import './index.scss';

const KYCInfo = ({ kycData }) => {
  return (
    <div className="kyc-container">
      {kycData.map((data, index) => (
        <div className="kyc-card" key={index}>
          <h2 className="kyc-title">{data.description}</h2>
          <table className="kyc-table">
            <tbody>
              <tr>
                <td className="kyc-field">Account Holders</td>
                <td className="kyc-value">{data.accountHolder}</td>
              </tr>
              <tr>
                <td className="kyc-field">Account Number</td>
                <td className="kyc-value">{data.accountNumber}</td>
              </tr>
              <tr>
                <td className="kyc-field">Bank Name</td>
                <td className="kyc-value">{data.bankName}</td>
              </tr>
              <tr>
                <td className="kyc-field">Account Type</td>
                <td className="kyc-value">{data.accountType}</td>
              </tr>
              <tr>
                <td className="kyc-field">Email</td>
                <td className="kyc-value">{data.email}</td>
              </tr>
              <tr>
                <td className="kyc-field">Phone Number</td>
                <td className="kyc-value">{data.phoneNumber || '-'}</td>
              </tr>
              <tr>
                <td className="kyc-field">Statement From</td>
                <td className="kyc-value">{data.statementFrom}</td>
              </tr>
              <tr>
                <td className="kyc-field">Statement To</td>
                <td className="kyc-value">{data.statementTo}</td>
              </tr>
              <tr>
                <td className="kyc-field">Txn Start Date</td>
                <td className="kyc-value">{data.txnStartDate}</td>
              </tr>
              <tr>
                <td className="kyc-field">Txn End Date</td>
                <td className="kyc-value">{data.txnEndDate}</td>
              </tr>
            </tbody>
          </table>
        </div>
      ))}
    </div>
  );
};

export default KYCInfo;
